/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Provider, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStore } from 'redux';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { withAnalytics } from '@trv/bi-digital-web-analytics';
import mockServer from './mock/mockServer';
import { stateReducer } from './app/reducers/quiz/stateReducer';
import { QuizForm } from './app/forms/QuizForm';
import ErrorBoundary from './shared/error/ErrorBoundary';
import { AutoTimeout } from './shared/index';
import './styles/app.scss';

if (process.env.REACT_APP_MOCK_API_TRUE) {
  mockServer();
}

const App = (props) => {
  if (window.location.protocol.indexOf('https') === 0) {
    const el = document.createElement('meta')
    el.setAttribute('http-equiv', 'Content-Security-Policy')
    el.setAttribute('content', 'upgrade-insecure-requests')
    document.head.append(el)
  }
  const { userId } = props;
  const store = createStore(
    stateReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  );
  const QuizFormWithAnalytics = withAnalytics(QuizForm);
  const ReduxQuizForm = connect((state) => ({
    state: {
      ...state,
    },
  }))(withRouter(QuizFormWithAnalytics));
  return (
    <ErrorBoundary>
      <AutoTimeout id="autotimeout-app">
        <>
          <div id="self-assessment-main" className="travelers-home pt-u-grey">
            <Provider store={store}>
              <Router history={createBrowserHistory()}>
                <ReduxQuizForm userId={userId} />
              </Router>
            </Provider>
          </div>
        </>
      </AutoTimeout>
    </ErrorBoundary>
  );
};

App.propTypes = {
  userId: PropTypes.string.isRequired,

};
export default App;
