import React from 'react';
import { ReactComponent as X124 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/x-1-24.svg';
import propTypes from 'prop-types';
import { Modal as TDSModal } from '@trv-ebus/tds';


const CancelModal = (props) => {
  const [onClose, setonClose] = React.useState('');
  const ModalElement = React.useRef();
  const ModalObject = React.useRef();

  React.useEffect(() => {
    ModalObject.current = new TDSModal(ModalElement.current);
    ModalObject.current.on('modal.closed', e => {
      // props.reset();
    });
    return () => {
      ModalObject.current.destroy(); //this is important to ensure event handlers are unsubscribed and that memory leaks are avoided: https://trv-design-system.cfn.travp.net/patterns/modules/modal/javascript/#methods
    };
  }, [onClose]);

  React.useEffect(() => {
    ModalObject.current.open();
  }, []);

  return (
    <div
      id="cancel-modal-small"
      className="tds-modal tds-modal__dialog--small"
      hidden
      role="dialog"
      data-enhanced-modal="true"
      data-do-not-move="true"
      ref={ModalElement}
    >
      <span data-dialog-tab-trap="" tabIndex="0" />
      <div role="document" className="tds-modal__dialog-container" aria-labelledby="cancel-modal-heading" aria-describedby="cancel-modal-description-md">
        <div className="tds-modal__dialog-header">
          <h2 id="cancel-modal-heading" className="tds-modal__dialog-title">Warning</h2>
        </div>
        <div className="tds-modal__dialog-body">
          <div id="cancel-modal-description-md" className="tds-modal__dialog-body-content">
            <p>
              Any progress you made in this self-assessment will be lost. Are you sure you want to proceed?
            </p>
          </div>
        </div>
        <div id="cancel-modal-footer-md" className="tds-modal__dialog-footer">
          <div className="tds-modal__dialog-button-container">
            <div className="tds-modal__dialog-button-layout">
              <button className="tds-button--primary" data-dismiss="modal" onClick={props.modalStayButtonClickHandler}>Stay</button>
              <button className="tds-button--secondary" aria-busy={props.isBusy} data-dismiss="modal" onClick={props.modalLeaveButtonClickHandler}>Leave</button>
            </div>
          </div>
        </div>
        <button aria-label="Dismiss dialog" className="tds-modal__dismiss" data-dismiss="modal" value="x" onClick={props.modalStayButtonClickHandler}>
          <X124 className=" tds-iconbutton__icon tds-icon-24" aria-hidden="true" focusable="false" />
        </button>
      </div>
      <span data-dialog-tab-trap="" tabIndex="0" />
    </div>
  );
};

export default CancelModal;
