import React from 'react';
import PropTypes from 'prop-types';
import { TdsProgress } from '@trv-tds/react';

export function ProgressBar({ completed, total }) {
  const completedPercentage = Math.round((completed / total) * 100);
  return (
    <TdsProgress
      id="ProgressBar"
      value={completedPercentage}
      aria-label="ProgressBar"
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax="100"
      aria-valuenow={completedPercentage}
      tabIndex="0"
      aria-live="polite"
      className="tds-mb-m"
    />
  );
}

ProgressBar.propTypes = {
  completed: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};
