/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { FourQuestionCard } from './FourQuestionCard';
import { EightQuestionCard } from './EightQuestionCard';
import { Alert } from '../../shared/Alert';

export class QuestionCard extends React.Component {
  constructor(props) {
    super(props);
    this.qstnRef = React.createRef();
    this.nextRef = React.createRef();
    this.backRef = React.createRef();
    this.getQuestionCard = this.getQuestionCard.bind(this);
    this.getAlert = this.getAlert.bind(this);
  }

  componentDidMount() {
    document.title = 'Steps - Self Assessment App';
    const { state } = this.props;
    const { asmtObjId } = state;
    if (
      this.qstnRef !== undefined
      && this.qstnRef.current !== undefined
      && this.qstnRef.current !== null
    ) {
      const refelement = document.getElementById('ProgressBar');
      if (refelement) window.scrollTo(0, refelement.offsetTop);
      this.props.analytics.track('nav', {
        page_name: `Self Assessment Question ${this.props.current}`,
        question_category: this.props.sectionName,
      });
      this.setState({ currentSectionNumber: this.props.current });
    }
  }

  componentDidUpdate() {
    if (this.props.current !== this.state.currentSectionNumber) {
      this.props.analytics.track('nav', {
        page_name: `Self Assessment Question ${this.props.current}`,
        question_category: this.props.sectionName,
      });
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentSectionNumber: this.props.current });
    }
  }

  getQuestionCard() {
    const {
      sectionName, question, selectedAnswer, onAnswer,
    } = this.props;
    const questionCard = question.answers.length > 4 ? (
      <EightQuestionCard
        analytics={this.props.analytics}
        sectionName={sectionName.replace(' & ', '').replace(' ', '')}
        questionNum={question.id}
        answers={question.answers}
        selectedAnswer={selectedAnswer}
        onAnswer={onAnswer}

      />
    ) : (
      <FourQuestionCard
        analytics={this.props.analytics}
        sectionName={sectionName.replace(' & ', '').replace(' ', '')}
        questionNum={question.id}
        answers={question.answers}
        selectedAnswer={selectedAnswer}
        onAnswer={onAnswer}
      />
    );
    return questionCard;
  }

  getAlert() {
    const { showAlert } = this.props;
    return showAlert === true ? (
      <div className="tds-col">
        {' '}
        <Alert id="error" alertType="error" content={<>Please select an answer</>} />
        {' '}
      </div>
    ) : ('');
  }

  getQuestionText() {
    const { question } = this.props;
    return { __html: question.text.replace(/[(]/g, ' <span class="question-card-example" > (').replace(/[)]/g, ') </span> ') };
  }

  render() {
    const {
      sectionName, current, total, question, showQuestion, onNext, onPrevious,
    } = this.props;
    return (
      <>
        <div className="tds-row tds-margin-lr-none tds-pb-l tds-pt-0">
          <div className="tds-col-auto">
            <h2 className="tds-h5 tds-bold">
              QUESTION
              {' '}
              {current}
              {' '}
              OF
              {' '}
              {total}
            </h2>
          </div>
        </div>
        <article className="pt-question-card">
          <div className="tds-row tds-margin-lr-none">
            <div id="topnext" style={{ outline: 'none' }} ref={this.qstnRef} className="tds-col-lg">
              <header className="pt-question-card__header" tabIndex="0">
                <div className="pt-question-card__h5 tds-h5">
                  <span className="section-stack">{sectionName}</span>
                </div>
                <Fade when={showQuestion}>
                  <span id="questionDescription">
                    <div className="tds-h1--small mob-tds-h1--small" style={{ color: '#ffffff' }}>
                      {' '}
                      {question.text}
                      {' '}
                    </div>
                    <div className="tds-h4" style={{ color: '#ffffff' }}>{question.description}</div>
                  </span>
                </Fade>
              </header>
            </div>
            <div className="tds-col-lg question-card-background-override">
              <div className="pt-question-card__content">
                {this.getAlert()}
                <Fade when={showQuestion}>
                  <div
                    role="radiogroup"
                    aria-labelledby="questionDescription"
                    className="pt-question-card__button-wrapper"
                  >
                    {this.getQuestionCard()}
                  </div>
                </Fade>
                <div className="tds-button-container">
                  <button
                    ref={this.nextRef}
                    className="tds-button--primary"
                    aria-busy="false"
                    id={
                      `nppt-${
                        sectionName.replace(' & ', '').replace(' ', '')
                      }-step-${
                        question.id
                      }-next`
                    }
                    type="button"
                    onClick={() => {
                      onNext(this.qstnRef, this.nextRef.current);
                    }}
                  >
                    Next
                  </button>
                  <button
                    ref={this.backRef}
                    className="tds-button--secondary"
                    id={
                      `nppt-${
                        sectionName.replace(' & ', '').replace(' ', '')
                      }-step-${
                        question.id
                      }-back`
                    }
                    type="button"
                    onClick={() => {
                      onPrevious(this.qstnRef);
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </article>
      </>
    );
  }
}

QuestionCard.propTypes = {
  analytics: PropTypes.instanceOf(Object).isRequired,
  current: PropTypes.number.isRequired,
  onAnswer: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  question: PropTypes.instanceOf(Object).isRequired,
  sectionName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  selectedAnswer: PropTypes.string,
  showAlert: PropTypes.bool.isRequired,
  showQuestion: PropTypes.bool.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  total: PropTypes.number.isRequired,
};
