import React from 'react';
import PropTypes from 'prop-types';
import appLogo from '@trv-ebus/tds-icons/dist/assets/icons/illustrative/svg/heartrate-1-72.svg';

// eslint-disable-next-line import/prefer-default-export
export function Title({ logoAlt, logotitle }) {
  return (
    <>
      <header className="pt-logo tds-mb-s">
        <h1 className="pt-logo__h1 sa-h1">
          <img className="pt-logo__h1-img" src={appLogo} alt={logoAlt} />
          {logotitle}
        </h1>
      </header>
    </>
  );
}

Title.propTypes = {
  logoAlt: PropTypes.string,
  logotitle: PropTypes.string.isRequired,
};

Title.defaultProps = {
  logoAlt: null,
};
