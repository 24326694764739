import React from 'react';
import '../../styles/notFound.scss';
export function AssessmentError(props) {
  return (
    <div className="tds-app-container-full-width">
      <div className="error-page-content">
        <h1 className="error-header tds-margin-tb-large ">We&apos;re Sorry</h1>
        <div className="error-card-override">
          <div className="card-block">
            <div>
              Something happened on our end and we were not able to save your answers. We apologize for the inconvenience and we are working on the issue.
            </div>
            <br />
            <div>If you'd like to start the test again from the beginning, click the button below.</div>
            <div className="tds-button-container tds-my-xxl tds-pb-xxl">
              <a
                id="btnCriticalPrimary"
                className="tds-button--primary"
                href={`/#/assessment/${props.asmtid}`}
              >
              RESTART TEST <br />              
              </a>
              <a
                id="btnCriticalSecondary"
                className="tds-button--secondary"
                href="https://riskcontrol.travelers.com/mytrc"
              >
              VISIT RISK CONTROL 
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


