function get(key) {
  if (!key) {
    return null;
  }
  return (
    decodeURIComponent(
      document.cookie.replace(
        new RegExp(
          `(?:(?:^|.*;)\\s*${
            encodeURIComponent(key).replace(/[-.+*]/g, '\\$&')
          }\\s*\\=\\s*([^;]*).*$)|^.*$`,
        ),
        '$1',
      ),
    ) || null
  );
}

function set(key, value, config) {
  const expires = config ? config.expires : null;
  const path = config ? config.path : null;
  const domain = config ? config.domain : null;
  const isSecure = config ? !!config.secure : null;
  let sExpires = '';

  if (!key || /^(?:expires|max-age|path|domain|secure)$/i.test(key)) {
    return;
  }

  if (expires) {
    switch (expires.constructor) {
      case Number:
        sExpires = expires === Infinity
          ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT'
          : `; max-age=${expires}`;
        break;
      case String:
        sExpires = `; expires=${expires}`;
        break;
      case Date:
        sExpires = `; expires=${expires.toUTCString()}`;
        break;
      default:
        break;
    }
  }
  document.cookie = `${encodeURIComponent(key)
  }=${
    encodeURIComponent(value)
  }${sExpires
  }${domain ? `; domain=${domain}` : ''
  }${path ? `; path=${path}` : ''
  }${isSecure ? '; secure' : ''}`;
}

function remove(key, config) {
  const path = config ? config.path : null;
  const domain = config ? config.domain : null;

  if (!this.has(key)) {
    return;
  }
  document.cookie = `${encodeURIComponent(key)
  }=; expires=Thu, 01 Jan 1970 00:00:00 GMT${
    domain ? `; domain=${domain}` : ''
  }${path ? `; path=${path}` : ''}`;
}

function has(key) {
  if (!key) {
    return false;
  }
  return new RegExp(
    `(?:^|;\\s*)${
      encodeURIComponent(key).replace(/[-.+*]/g, '\\$&')
    }\\s*\\=`,
  ).test(document.cookie);
}

const cookies = {
  get,
  set,
  remove,
  has,
};
export default cookies;
