import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldError } from './FieldError';

export class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.inputElement = null;
    this.labelElement = null;
  }

  render() {
    const {
      label, id, required, defaultValue, onChange, options = [], validityMessages, showErrors,
    } = this.props;
    return (
      <>
        <div className="tds-field">
          <label className="tds-field__label" htmlFor={id} aria-required="true" ref={(element) => { (this.labelElement = element); }}>
            {`${label}${required ? '' : ' (optional)'}`}
          </label>
          <select
            className="tds-field__input"
            aria-required="true"
            id={id}
            name={id}
            required={required}
            value={defaultValue}
            onChange={onChange}
            ref={(element) => { (this.inputElement = element); }}
          >
            {options.map((option) => (
              <React.Fragment key={`${id}_${option.name}`}>
                <option value={option.value}>{option.name}</option>
              </React.Fragment>
            ))}
          </select>
          <FieldError inputElement={this.inputElement} labelElement={this.labelElement} validityMessages={validityMessages} showErrors={showErrors} />
        </div>
      </>
    );
  }
}

Dropdown.propTypes = {
  // eslint-disable-next-line react/require-default-props
  defaultValue: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  required: PropTypes.bool,
  showErrors: PropTypes.bool,
  validityMessages: PropTypes.instanceOf(Object).isRequired,
};
Dropdown.defaultProps = {
  required: false,
  showErrors: false,
};
