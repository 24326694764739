import React from 'react';
import '../../styles/notFound.scss';
export function ApplicationError() {
  return (
    <div className="tds-app-container-full-width rw-error-contrast-app d-block">
      <div className="error-page-content">
        <h1 className="error-header tds-margin-tb-large">We&apos;re Sorry</h1>
        <div className="error-card-override">
          <div className="card-block">
            <div>Self-Assessment is currently unavailable. We apologize for the inconvenience and we are working on the issue. Please try back soon.</div>
            <br />
            <div>Until we are able to fix the issue, visit <a href="https://riskcontrol.travelers.com/mytrc" rel="noopener noreferrer">Risk Control </a>for information on the latest risk topics and insights facing your industry.</div>
            <div className="tds-button-container tds-my-xxl tds-pb-xxl">
              <a
                id="btnCriticalPrimary"
                className="tds-button--primary"
                href="https://riskcontrol.travelers.com/mytrc"
              >
                VISIT RISK CONTROL
              </a>

              <button
                id="btnCriticalSecondary"
                className="tds-button--secondary"
                onClick={e => { window.location.reload(); }}
              >
                GO BACK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
