/* eslint-disable */
import uuid from 'uuid/v4';
import cookies from './cookies';

function getSessionId() {
    let sessionId = cookies.get('TravSessionId');
    const currentTime = new Date().getTime();
  
    if (sessionId) {
      const sessionTime = sessionId.split('_')[1] || currentTime;
      if (currentTime - sessionTime > 1800000) {
        sessionId = '';
      }
    }
  
    if (!sessionId) {
      const cookieConfig = {
        domain: '.travelers.com',
        path: '/',
      };
      sessionId = uuid() + '_' + currentTime;
      cookies.set('TravSessionId', sessionId, cookieConfig);
    }
  
    return sessionId;
}

export { getSessionId };

