/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { disclaimer, initialText, chooserPageContent } from './utils/modules/chooserPageData';
import Card from './shared/layout/card';

const homePageId = 'sa-asmt-card'
document.title = 'Travelers Self-Assessments';

const HomePage = (props) => (
  <>

    <div className="tds-container">
      <header className="pt-logo-progress-bar__partial">
        <h1 className="sa-h1">
          Travelers Self-Assessments
        </h1>
      </header>
      <div id="sa-header-text">
        {initialText}
      </div>
      <div className="tds-m-l tds-row tds-justify-content-center" id={`${homePageId}-row`}>
        {chooserPageContent.map((asmt, index) => (
          <div key={asmt.asmtObjID} className="tds-col-auto tds-m-m" id={`${homePageId}-${index}`}>
            <Card homePageId={homePageId} loadQuizData={props.loadQuizData} asmt={asmt} index={index}>{props.children}</Card>
          </div>
        ))}
      </div>
    </div>
    <div className="pt-disclaimer tds-container-max-width">
      <p className="pt-disclaimer__p tds-container">{disclaimer}</p>
    </div>

  </>
)
HomePage.prototype = {
  loadQuizData: PropTypes.func.isRequired,
  children: PropTypes.any,
}
export default HomePage;
