/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';

export function FourQuestionCard({
  sectionName, questionNum, answers, selectedAnswer, onAnswer,
}) {
  return answers.map((answer) => {
    const isSelected = answer.code === (selectedAnswer ? selectedAnswer.answerCode : null);
    return (
      <div className="tds-col tds-py-xs " key={answer.code}>
        <button
          type="button"
          id={`nppt-${sectionName}-step-${questionNum}-${answer.code}`}
          role="radio"
          aria-checked={isSelected}
          className={`pt-button-w-question ${
            answer.code === (selectedAnswer ? selectedAnswer.answerCode : null)
              ? 'pt-button-w-question--selected'
              : ''
          }`}
          onClick={() => onAnswer(answer)}
          dangerouslySetInnerHTML={{ __html: answer.text }}
        />
      </div>
    );
  });
}

FourQuestionCard.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.object),
  selectedAnswer: PropTypes.instanceOf(Object),
  onAnswer: PropTypes.func,
};
