import React from 'react';

export function Spinner() {
  return (
    <div style={{ height: '500px', paddingTop: '130px' }}>
      <div className="loader" />
    </div>
  );
}

export default Spinner();
