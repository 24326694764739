import React from 'react';
import PropTypes from 'prop-types';

const properties = ['valueMissing', 'typeMismatch', 'tooShort', 'tooLong',
  'stepMismatch', 'rangeUnderflow', 'rangeOverflow', 'patternMismatch',
  'customError', 'badInput'];

function addErrorClasses(inputElement, labelElement, divId) {
  inputElement.setAttribute('aria-describedby', divId);
  inputElement.setAttribute('aria-invalid', true);
  labelElement.classList.add('tds-check--error');
}

function removeErrorClasses(inputElement, labelElement) {
  inputElement.removeAttribute('aria-describedby');
  inputElement.removeAttribute('aria-invalid');
  labelElement.classList.remove('tds-check--error');
}

export function FieldError({
  showErrors, validityMessages, inputElement, labelElement,
}) {
  let element = '';
  const validity = inputElement && inputElement.validity;
  const inputElementId = inputElement && inputElement.id;
  const divId = `${inputElementId}-error`;

  if (validity && showErrors) {
    for (const index in properties) {
      if (validity[properties[index]] === true) {
        element = (
          <div className="tds-field--error">
            <div id={divId} aria-atomic="true" aria-live="assertive" aria-relevant="additions text" className="tds-field__message" role="alert">
              <p>{validityMessages ? validityMessages[properties[index]] : ''}</p>
            </div>
          </div>
        );
        addErrorClasses(inputElement, labelElement, divId);
      }
    }
    if (validity.valid) {
      removeErrorClasses(inputElement, labelElement);
    }
  }
  return <>{element}</>;
}

FieldError.propTypes = {
  showErrors: PropTypes.bool,
  validityMessages: PropTypes.instanceOf(Object),
  inputElement: PropTypes.instanceOf(Element),
  labelElement: PropTypes.instanceOf(Element),
};

FieldError.defaultProps = {
  showErrors: null,
  validityMessages: null,
  inputElement: null,
  labelElement: null,
};
