/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from '../shared/ProgressBar';
import { Title } from './Title';

// eslint-disable-next-line import/prefer-default-export
export function Page({
  logoAlt, disclaimer, completed, total, children, state,
}) {
  return (
    <>
      <div className="tds-container">

        <section id="content-link" tabIndex="-1">
          <section className="pt-logo-progress-bar__partial">
            <Title logoAlt={logoAlt} logotitle={state.title} />
            <ProgressBar completed={completed} total={total} />
          </section>
          {children}
        </section>
      </div>
      <div className="pt-disclaimer tds">
        <p className="pt-disclaimer__p tds-container">{disclaimer}</p>
      </div>
    </>
  );
}

Page.propTypes = {
  children: PropTypes.any,
  completed: PropTypes.number,
  disclaimer: PropTypes.string,
  logoAlt: PropTypes.string,
  total: PropTypes.number,
  state: PropTypes.any,
};
Page.defaultProps = {
  children: null,
  completed: null,
  disclaimer: null,
  logoAlt: null,
  total: null,
  state: null,
};
