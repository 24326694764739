/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import Image from './image';

export function IntroCard({ intro, onNext }) {
  return (
    <>
      <article className="tds-row tds-mx-0 tds-mb-xxxl">
        <header className="tds-col-md-6 tds-py-xxxl tds-px-xl rw-contrast-block">
          <h1 className="tds-h1--small tds-row-4">{intro.imageTitle}</h1>
          <Image src={intro.image} alt={intro.imageAlt} />
        </header>
        <section className="tds-col-md-6 rw-block_right tds-py-xl">
          <div className="tds-row rw-block_right">
            <div className="pt-intro-card__wrapper tds-pb-l">
              <h2 className="tds-h2">{intro.header}</h2>
              <div id="rw-intro-text" className="tds-body tds-large">{intro.text}</div>
            </div>
            <button
              type="button"
              className=" tds-button--primary"
              id={intro.buttonId}
              onClick={onNext}
            >
              {intro.buttonText}
            </button>
          </div>
        </section>
      </article>
    </>
  );
}

IntroCard.propTypes = {
  intro: PropTypes.instanceOf(Object),
  onNext: PropTypes.func,
};

IntroCard.defaultProps = {
  intro: null,
  onNext: null,
};
