/* eslint-disable max-len */
export const initialText = 'Learn about ways to manage risks and prevent losses with Travelers’ self-assessments. Depending on your answers, each self-assessment may provide you with recommendations and links to helpful resources to support your improvement efforts.';
export const chooserPageContent = [
  {
    asmtObjID: '6435e2f4ec63f00012a49028',
    assessmentTitle: 'Safety Management',
    assessmentDescription: 'Evaluate your safety management program and learn about ways you could do more to keep your employees and business safe.',
    assessmentImage: '/iw-images/business-insurance/pressure-test-quiz/GettyImages-879814122_480x300.jpg',
    assessmentLink: '/assessment/6435e2f4ec63f00012a49028',
  },
  {
    asmtObjID: '5fecad3dd63fac001b9f2998',
    assessmentTitle: 'Small Business',
    assessmentDescription: 'Assess how protected your business is from common risks such as workplace injuries, vehicle accidents, property damage and more.',
    assessmentImage: '/iw-images/business-insurance/pressure-test-quiz/self-assessment-for-business.jpg',
    assessmentLink: '/assessment/5fecad3dd63fac001b9f2998',
  },
  {
    asmtObjID: '6127dc03787548000e4e7e72',
    assessmentTitle: 'Industrial Ergonomics',
    assessmentDescription: 'Learn how well your employees are protected against ergonomic risks in the workplace and identify where you may be able to improve your ergonomics program.',
    assessmentImage: '/iw-images/business-insurance/pressure-test-quiz/industrial-ergonomics.jpg',
    assessmentLink: '/assessment/6127dc03787548000e4e7e72',
  },
  {
    asmtObjID: '6127dc03787548000e4e7f0f',
    assessmentTitle: 'Fleet Safety Management',
    assessmentDescription: 'Understand how well your organization is managing fleet safety. There could be opportunities to better protect your employees, vehicles and company.',
    assessmentImage: '/iw-images/business-insurance/pressure-test-quiz/fleet-safety-management.jpg',
    assessmentLink: '/assessment/6127dc03787548000e4e7f0f',
  },
  {
    asmtObjID: '6127dc03787548000e4e7f97',
    assessmentTitle: 'Post-Injury Management',
    assessmentDescription: 'Evaluate your post-injury management program to learn if there are ways to help improve injured worker care.',
    assessmentImage: '/iw-images/business-insurance/pressure-test-quiz/post-injury-management.jpg',
    assessmentLink: '/assessment/6127dc03787548000e4e7f97',
  },
  {
    asmtObjID: '615f4cc85633c400182cf7a3',
    assessmentTitle: 'Property and Fire safety',
    assessmentDescription: 'See how well your property is protected from fire and other causes of property damage. Identify if there are more ways to help keep your business safe.',
    assessmentImage: '/iw-images/business-insurance/pressure-test-quiz/property-and-fire-safety.jpg',
    assessmentLink: '/assessment/615f4cc85633c400182cf7a3',
  },
]

export const disclaimer = "Disclaimer: This self-assessment is intended to provide guidance and resources to help you mitigate risks common to many businesses. It does not address every possible risk your business might face.  Self-assessment results are not an endorsement that all risks and exposures are being managed effectively. Any tasks identified as a result of this self-assessment are intended to help you improve your safety program. This is not a complete list of actions that may be necessary to help you mitigate all your risks. The information you provide while taking this self-assessment will only be used in an aggregated and anonymized format to enhance our services to the users; it will not be used to identify your company or your company's risk in any particular area. We will not sell or market your information to any third party. We reserve the right to change the data or output at any time without notice. Your participation in this assessment does not amend, or otherwise affect, the provisions or coverage of any insurance policy or bond issued by Travelers or any of its subsidiaries, nor is it a representation that coverage does or does not exist for any particular claim or loss under any such policy or bond. Coverage depends on the facts and circumstances involved in the claim or loss, all applicable policy or bond provisions, and any applicable law.";
