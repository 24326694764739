import React from 'react';
import PropTypes from 'prop-types';
import TDSHeader from '../header';
import TDSFooter from '../footer';
import '@trv-tds/core/dist/sass/core.scss';

const FrameContainer = props => {
  return (
    <>
      <TDSHeader header={props.menu} />
      <main className="tds-body rw-contrast-app">
        <div className={props.classes || 'tds-container'}>{props.children}</div>
      </main>
      <TDSFooter footer={props.menu} userId={props.userId} />
    </>
  );
};

FrameContainer.propTypes = {
  props: PropTypes.object,
};

export default FrameContainer;
