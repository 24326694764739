/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-const */
import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Checkbox, Button, Alert } from 'trv-tds-react';
import { getAssessmentQuestions } from '../../../api/QuizApi';
import {
  TOPIC_SELECTED_VALUES, QUIZ_LOADED,
  QUIZ_LOAD_ERROR,
} from '../../../actions/quiz/states';
class TopicChecklist extends React.Component {
  constructor(props) {
    super(props);
    const { topicList } = props;
    this.state = {
      topicList: JSON.parse(JSON.stringify(topicList)),
      IsAnyTopicChecked: true,
      IsSubmitted: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  onChangeHandler = (event) => {
    const { topicList, IsSubmitted } = this.state;
    topicList[event.currentTarget.id].IsChecked = !topicList[event.currentTarget.id].IsChecked;
    this.setState({ topicList });
    if (IsSubmitted) this.CheckAnyTopicSelected();
  }

    handleTealiumNextCall = (selectedTopics) => {
      this.props.analytics.track('click', {
        event_category: 'page tag',
        event_action: 'multi select',
        event_detail: selectedTopics.join('|'),
        event_label: 'topic',
        page_cd: 'Topic',
        pii_filter: false,
      });

      this.props.analytics.track('click', {
        event_category: 'page tag',
        event_action: 'button click',
        event_detail: 'next page',
        event_label: 'takethetest',
        page_cd: 'Topic',
        pii_filter: false,
      });
    }

  handleSubmit = (e) => {
    this.setState({ IsSubmitted: true });
    if (this.CheckAnyTopicSelected()) {
      const { topicList: { topics }, state } = this.props;
      const { topicList } = this.state;
      let selectedTopics = topicList.filter((t) => t.IsChecked).map((m) => m.code);

      let assessmentQuestionBody = { asmtObjId: state.asmtObjId, topics: selectedTopics };
      this.handleTealiumNextCall(selectedTopics);
      let oldTopicList = state.quizResponse.topicList || topics;
      let prevSelectedTopics = oldTopicList.filter((t) => t.IsChecked).map((m) => m.code);
      if (prevSelectedTopics.length === selectedTopics.length && state.topicList) {
        let IsTopicSelectionChanged = false;
        for (let i = 0; i < prevSelectedTopics.length; i += 1) {
          if (prevSelectedTopics[i] !== selectedTopics[i]) {
            IsTopicSelectionChanged = true;
            break;
          }
        }

        if (!IsTopicSelectionChanged) {
          this.props.history.push(`/assessment/${state.asmtObjId}/step/1`);
          e.preventDefault();
          return;
        }
      }

      getAssessmentQuestions(assessmentQuestionBody,
        (res) => {
          const { dispatch } = this.props;
          const { quizResponse, asmtObjId } = state;
          dispatch({
            type: TOPIC_SELECTED_VALUES,
            payload: { topicList },
          });

          let totalQue;
          let quiz = res.data.msgDetails;
          // eslint-disable-next-line no-shadow
          totalQue = quiz.reduce((totalQue, section) => {
            // eslint-disable-next-line no-param-reassign
            totalQue += section.assessmentQuestions.length;
            return totalQue;
          }, 0);

          dispatch({
            type: QUIZ_LOADED,
            payload: {
              quiz,
              total: totalQue,
              isQuizResponseReady: true,
              showQuestion: true,
              quizResponse: {
                startTime: new Date().toJSON(),
                sections: quiz.map((section) => new Array(section.assessmentQuestions.length)),
                userAttributes: quizResponse.userAttributes,
                topicList,
              },
            },
          });

          this.props.history.push(`/assessment/${asmtObjId}/step/1`);
        },
        (err) => {
          const { dispatch } = this.props;
          dispatch({ type: QUIZ_LOAD_ERROR, payload: { error: err } });
          this.setState(() => {
            this.props.history.push(`/assessment/${state.asmtObjId}/assessmentError`);
          });
          // this.setState({ topicCard: null, IsLoading: true });
        });
      // window.location.hash = '/step/1';
    }
    e.preventDefault();
  };

  handleBackBtnClick = (e) => {
    const { topicList } = this.state;
    const { dispatch, state, analytics } = this.props;
    dispatch({
      type: TOPIC_SELECTED_VALUES,
      payload: { topicList },
    });
    analytics.track('click', {
      event_category: 'page tag',
      event_action: 'button click',
      event_detail: 'previous page',
      event_label: 'back',
      page_cd: 'Topic',
      pii_filter: false,
    });
    this.props.history.push(`/assessment/${state.asmtObjId}/Demographics`);
  };

  CheckAnyTopicSelected() {
    // eslint-disable-next-line prefer-const
    let { topicList, IsAnyTopicChecked } = this.state;
    IsAnyTopicChecked = topicList.some((t) => t.IsChecked);
    this.setState({ IsAnyTopicChecked });
    return IsAnyTopicChecked;
  }

  render() {
    const { topicList, IsAnyTopicChecked, IsSubmitted } = this.state;

    return (
      <form>
        <div role="group" className="tds-pl-s" aria-labelledby="topics-text">
          {!IsAnyTopicChecked && IsSubmitted
            ? (
              <Alert
                typeName="inline"
                variant="error"
                message="Please select one or more topics"
                dismissable={false}
              />
            ) : null}

          <div className="tds-row tds-align-items-center tds-justify-content-center tds-margin-left-small">
            {topicList.map((item, i) => (

              <div key={`TopicCheckBox${item.id}`} className="tds-col-md-5 tds-col-lg-6 tds-px-xs">
                <Checkbox
                  checked={item.IsChecked}
                  onChange={this.onChangeHandler}
                  variant="default"
                  id={i}
                  name="TopicCheckList"
                  value={item.code}
                  disabled={false}
                >
                  {item.description}
                </Checkbox>
              </div>

            ))}
          </div>
        </div>

        <div className="tds-row tds-justify-content-center">
          <div className="tds-button-container">
            <Button variant="primary" onClick={this.handleSubmit}> START ASSESSMENT </Button>
            <Button variant="secondary" onClick={this.handleBackBtnClick}> BACK </Button>
          </div>
        </div>

      </form>
    );
  }
}

TopicChecklist.propTypes = {
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.any.isRequired,
  analytics: PropTypes.instanceOf(Object).isRequired,
  topicList: PropTypes.arrayOf(PropTypes.any).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default TopicChecklist;
