import React, { Component } from 'react';
import { render } from 'react-dom';
import axios from 'axios';
import FrameContainer from './shared/layout/frameContainer';
import App from './App';
import '@trv-tds/core/dist/sass/core.scss';
import { getCommonMenu } from './app/api/QuizApi';
import { Spinner } from './app/components/shared/Spinner';
import HomePage from './HomePage';

try {
  const accessObject = JSON.parse(window.sessionStorage.getItem('okta-token-storage'));
  if (accessObject && accessObject.accessToken) {
    const { accessToken } = accessObject.accessToken;
    if (accessObject.accessToken && accessObject.accessToken.accessToken) {
      const myInterceptor = axios.interceptors.request.use((config) => {
        config.headers.Authorization = `${accessObject.accessToken.accessToken}`;
        return config;
      });
      axios.interceptors.request.eject(myInterceptor);
      axios.interceptors.response.use((response) => response,
        (error) => {
          const originalRequest = error.config;
          if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true; // to avoid infinite loops.
            try {
              const newToken = JSON.parse(window.sessionStorage.getItem('okta-token-storage'));

              axios.defaults.headers.common.Authorization = `Bearer ${newToken.accessToken.accessToken}`;
              originalRequest.headers.Authorization = `${newToken.accessToken.accessToken}`;
              return axios(originalRequest);
            } catch (refreshError) {
              window.sessionStorage.clear();
              window.location.replace('/login')
              return Promise.reject(refreshError);
            }
          }
          return Promise.reject(error);
        });
    }
  }
} catch (err) {
  console.log('Error while setting authorization header', err);
}
class AppContainer extends Component {
  constructor() {
    super();
    this.state = {
      userId: null,
      menu: null,
      isAppLoading: true,
    };
  }

  componentDidMount = () => {
    if (window.sessionStorage.getItem('okta-token-storage')) {
      const accessObject = JSON.parse(
        window.sessionStorage.getItem('okta-token-storage'),
      );
      if (accessObject.accessToken && accessObject.accessToken.accessToken) {
        getCommonMenu(
          (res) => {
            this.setState({
              userId: res.data.msgDetails.id,
              menu: res.data.msgDetails,
              isAppLoading: false,
            });
          },
          (err) => {
            this.setState({ isAppLoading: false });
            throw new Error(err);
          },
        );
      } else {
        this.setState({ isAppLoading: false });
      }
    } else {
      this.setState({ isAppLoading: false });
    }
  }

  render() {
    const {
      isAppLoading, menu, userId,
    } = this.state;
    return (
      <>
        {isAppLoading && <Spinner />}
        {!isAppLoading && (
          <FrameContainer menu={menu} userId={userId} classes="tds-container--full-width" id="row">
            <App userId={userId} />
          </FrameContainer>
        )}

      </>
    );
  }
}
export default AppContainer;
