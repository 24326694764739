import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export default function Image({ src, alt }) {
  const defaultImg = '/iw-images/business-insurance/pressure-test-quiz/collage.svg';
  const [imgsrc, setImgsrc] = useState(src);

  useEffect(() => {
    if (!src) setImgsrc(defaultImg)
    else setImgsrc(src)
  }, [src])

  const onError = () => {
    setImgsrc(defaultImg);
  };

  return (
    <img
      src={imgsrc}
      alt={alt}
      onError={onError}
      className="pt-intro-card__header-img tds-row-7"
      role="presentation"
      style={{ maxHeight: '350px' }}
    />
  );
}

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

Image.defaultProps = {
  src: null,
  alt: null,
};
