/* eslint-disable import/prefer-default-export */
/* eslint-disable no-case-declarations */
import {
  QUIZ_LOADED,
  RESULT_LOADED,
  RESULT_LOAD_ERROR,
  QUESTION_ANSWERED,
  QUIZ_LOAD_ERROR,
  QUIZ_SUBMITTED,
  QUIZ_SUBMIT_ERROR,
  QUESTION_UNANSWERED,
  NAVIGATE_TO_STEP,
  UPDATE_STATE,
  TOPIC_SELECTED_VALUES,
  TOPIC_CARD,
  DEMOGRAPHICS_DATA,
  RETAKE_TEST,
  ASSESSMENTOBJID,
  UPDATE_ASSESSMENT_TITLE,
  RESET_QUIZ_DATA,
} from '../../actions/quiz/states';

const initialState = {
  asmtObjId: null,
  error: null,
  focusDiv: 'top',
  isQuestionUnanswered: false,
  isQuizResponseReady: true,
  quiz: null,
  quizResponse: {
    startTime: undefined,
    assessmentTopics: [],
    userAttributes: [],
    topicList: [],
  },
  quizSubmitResults: null,
  showQuestion: false,
  title: null,
  topicCard: null,
  total: 0,
};

export function stateReducer(state = JSON.parse(JSON.stringify(initialState)), action) {
  let newState;
  switch (action.type) {
    case QUIZ_LOADED:
      newState = {
        ...state,
        ...action.payload,
        title: action.payload.preAssessmentData ? action.payload.preAssessmentData.title : state.title,
      };
      break;

    case QUIZ_LOAD_ERROR:
    case RESULT_LOADED:
    case RESULT_LOAD_ERROR:
      newState = {
        ...state,
        ...action.payload,
      };

      break;

    case QUESTION_ANSWERED:
      const { quiz, quizResponse } = { ...state };
      const { answer, stepNumber } = action.payload;

      let steps = 0;
      let index;
      let questionFound = false;

      for (let i = 0; i < quizResponse.sections.length; i += 1) {
        if (steps + quizResponse.sections[i].length < stepNumber) {
          steps += quizResponse.sections[i].length;
        } else {
          index = stepNumber - steps - 1;
          const currentSec = quiz[i];
          const currentQstn = currentSec.assessmentQuestions[index];

          quizResponse.sections[i][index] = {
            code: currentSec.code,
            assessmentQuestion: { answerCode: answer.code, id: currentQstn.id },
          };
          questionFound = true;
          break;
        }
      }

      // the question was not found in the sections
      if (!questionFound) {
        // loop through the demographics to find the question
        for (let i = 0; i < quizResponse.userAttributes.length; i += 1) {
          if (steps + i === stepNumber - 1) {
            index = i;
            quizResponse.userAttributes[index] = {
              id: quiz.userAttributes[index].id,
              answerCode: answer.code,
            };
            break;
          }
        }
      }

      newState = {
        ...state,
        quizResponse,
        isQuestionUnanswered: false,
      };
      break;

    case QUESTION_UNANSWERED:
      newState = {
        ...state,
        isQuestionUnanswered: true,
      };
      break;

    case NAVIGATE_TO_STEP:
      newState = {
        ...state,
        isQuestionUnanswered: false,
      };
      break;

    case QUIZ_SUBMITTED:
      newState = {
        ...state,
        quizSubmitResults: action.payload,
        isQuestionUnanswered: false,
      };
      break;

    case QUIZ_SUBMIT_ERROR:
      newState = {
        ...state,
        error: action.payload,
      };
      break;

    case UPDATE_STATE:
      newState = {
        ...state,
        ...action.payload,
      };
      break;
    case TOPIC_SELECTED_VALUES:
      newState = {
        ...state,
        ...action.payload,
      };
      break;
    case TOPIC_CARD:
      newState = {
        ...state,
        ...action.payload,
      };
      break;
    case DEMOGRAPHICS_DATA:
      newState = {
        ...state,
        ...action.payload,
      };
      break;
    case RETAKE_TEST:
      newState = {
        ...state,
        ...action.payload,
      };
      break;
    case ASSESSMENTOBJID:
      newState = {
        ...state,
        asmtObjId: action.asmtObjId,
      };
      break;
    case UPDATE_ASSESSMENT_TITLE:
      newState = {
        ...state,
        title: `${action.payload.assessmentDescription} Self-Assessment`,
        asmtObjId: action.payload.asmtObjId,
      };
      break;
    case RESET_QUIZ_DATA:
      newState = JSON.parse(JSON.stringify(initialState));
      break;
    default:
      newState = { ...state };
      break;
  }

  return newState;
}
