import React from 'react';
// import './modal.scss';
import PropTypes from 'prop-types';
import CancelModal from './CancelModal';

function ModalDialog(props) {
  return (
    <CancelModal {...props}/>
  );
}

ModalDialog.propTypes = {
  children: PropTypes.any,
};

export default ModalDialog;
