import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button, Alert } from 'trv-tds-react';
import PropTypes from 'prop-types';
import { Dropdown } from '../../shared/Dropdown';

export default function Demographics(props) {
  const {
    DemographicsData,
    IsAllSelect,
    IsDisplayValidityMsg,
    formdata,
    handleOnChange,
    handleSubmitOnClick,
    handleBackOnClick,
  } = props;

  return (

    <form>
      <div role="group" aria-labelledby="demographics-text">
        <div className="tds-col tds-align-items-center tds-justify-content-center">
          <div>
            {!IsAllSelect ? <Alert typeName="inline" variant="error" message="Please select an answer below" dismissable={false} /> : null}
          </div>
          {DemographicsData === null ? <div /> : (
            <>

              {DemographicsData.map((d) => (
                <div key={d.category} id={`div_${d.category}`} className="tds-row-md-12">
                  <Dropdown
                    name={d.category}
                    label={`${d.label}`}
                    id={d.category}
                    required
                    onChange={handleOnChange}
                    defaultValue={formdata[d.category] === null ? '' : formdata[d.category]}
                    options={d.options.map((ind) => ({ name: ind.description, value: ind.code }))}
                    showErrors={IsDisplayValidityMsg}
                    validityMessages={{ valueMissing: `Please select ${d.category}` }}
                  />
                  <br />
                </div>

              ))}

            </>
          )}
        </div>
        <div className="tds-row tds-justify-content-center">
          <div className="tds-button-container">
            <Button variant="primary" onClick={handleSubmitOnClick}> NEXT </Button>
            <Button variant="secondary" onClick={handleBackOnClick}> BACK </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

Demographics.propTypes = {
  DemographicsData: PropTypes.instanceOf(Object),
  IsAllSelect: PropTypes.bool,
  IsDisplayValidityMsg: PropTypes.bool,
  formdata: PropTypes.instanceOf(Object),
  handleBackOnClick: PropTypes.func,
  handleOnChange: PropTypes.func,
  handleSubmitOnClick: PropTypes.func,
};

Demographics.defaultProps = {
  DemographicsData: null,
  IsAllSelect: null,
  IsDisplayValidityMsg: null,
  formdata: null,
  handleBackOnClick: null,
  handleOnChange: null,
  handleSubmitOnClick: null,
};
