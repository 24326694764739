import axios from 'axios';

export function getPreAssessment(asmtObjId, successCallback, errorCallback) {
  axios
    .get(`/api/assessmentXPI/getPreAssessment/${asmtObjId}`)
    .then((response) => {
      successCallback(response);
    })
    .catch((err) => {
      errorCallback(err);
    });
}

export function getTopicByAssessment(asmtObjId, successCallback, errorCallback) {
  axios
    .get(`/api/assessmentXPI/getTopicByAssessment/${asmtObjId}`)
    .then((response) => {
      successCallback(response);
    })
    .catch((err) => {
      errorCallback(err);
    });
}
export function getResultByAssessmentId(assessmentInstanceId, successCallback, errorCallback) {
  axios
    .get(`/api/assessmentXPI/getAsmntResultByInstance/${assessmentInstanceId}`)
    .then((response) => {
      successCallback(response.data);
    })
    .catch((err) => {
      errorCallback(err);
    });
}
export function getAssessmentQuestions(assessmentQuestionBody, successCallback, errorCallback) {
  axios
    .put('/api/assessmentXPI/getAssessmentQuestions/', assessmentQuestionBody)
    .then((response) => {
      successCallback(response);
    })
    .catch((err) => {
      errorCallback(err);
    });
}

export function submitAssessment(assessmentUserResponse, successCallback, errorCallback) {
  axios
    .post('/api/assessmentXPI/submitAssessment/', assessmentUserResponse)
    .then((response) => {
      successCallback(response);
    })
    .catch((err) => {
      errorCallback(err);
    });
}

export function getCommonMenu(successCallback, errorCallback) {
  axios
    .get('/api/assessmentXPI/getcommonmenu')
    .then((response) => {
      successCallback(response);
    })
    .catch((err) => {
      errorCallback(err);
    });
}
