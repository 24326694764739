export const UPDATE_STATE = 'UPDATE_STATE';
export const QUIZ_LOADED = 'QUIZ_LOADED';
export const QUIZ_LOAD_ERROR = 'QUIZ_LOAD_ERROR';

export const RESULT_LOADED = 'RESULT_LOADED';
export const RESULT_LOAD_ERROR = 'RESULT_LOAD_ERROR';

export const QUESTION_ANSWERED = 'QUESTION_ANSWERED';
export const QUESTION_UNANSWERED = 'QUESTION_UNANSWERED';

export const NAVIGATE_TO_STEP = 'NAVIGATE_TO_STEP';

export const QUIZ_SUBMITTED = 'QUIZ_SUBMITTED';
export const QUIZ_SUBMIT_ERROR = 'QUIZ_SUBMIT_ERROR';

export const TOPIC_SELECTED_VALUES = 'TOPIC_SELECTED_VALUES';

export const TOPIC_CARD = 'TOPIC_CARD';

export const DEMOGRAPHICS_DATA = 'DEMOGRAPHICS_DATA';

export const RETAKE_TEST = 'RETAKE_TEST';
export const ASSESSMENTOBJID = 'ASSESSMENTOBJID';
export const UPDATE_ASSESSMENT_TITLE = 'UPDATE_ASSESSMENT_TITLE';

export const RESET_QUIZ_DATA = 'RESET_QUIZ_DATA';
