/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import axios from 'axios';
// eslint-disable-next-line camelcase
import { AnalyticsProvider, Analytics_Tealium } from '@trv/bi-digital-web-analytics';
import RiskWatchResult from 'bi-digital-assessment-results-ui/dist/index';
import { Switch, Route, Redirect } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import oktaAuth from '../../oktaConfig';
import { IntroCard, Page, QuestionCard } from '../components/quiz';
import { getResultByAssessmentId, getPreAssessment, submitAssessment } from '../api/QuizApi';
import { Spinner } from '../components/shared/Spinner';
import { AssessmentError } from '../../shared/error/AssessmentError';
import { getSessionId } from '../../utils/analytics';
import {
  ASSESSMENTOBJID,
  RESULT_LOAD_ERROR,
  QUESTION_ANSWERED,
  QUIZ_LOADED,
  QUIZ_LOAD_ERROR,
  RESULT_LOADED,
  QUIZ_SUBMITTED,
  QUIZ_SUBMIT_ERROR,
  QUESTION_UNANSWERED,
  NAVIGATE_TO_STEP,
  RETAKE_TEST,
  UPDATE_ASSESSMENT_TITLE,
  RESET_QUIZ_DATA,
} from '../actions/quiz/states';
import TopicPage from '../components/quiz/TopicPageDesign/TopicPage';
import DemographicsCard from '../components/quiz/Demographics/DemographicsCard';
import HomePage from '../../HomePage';
let queryParamQuizInstanceId;
const defaultAsmtObjId = process.env.REACT_APP_DEFAULT_ASMT_ID;

const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  window.location.href = window.location.origin + originalUri
}
export class QuizForm extends Component {
  constructor(props) {
    super(props);

    // eslint-disable-next-line react/no-unused-state
    this.state = { analytics: null }; // This should be taken from the logged in user.

    this.handleNextStage = this.handleNextStage.bind(this);
    this.handleNextQuestion = this.handleNextQuestion.bind(this);
    this.handlePreviousQuestion = this.handlePreviousQuestion.bind(this);
    this.handleNextDemographicQuestion = this.handleNextDemographicQuestion.bind(this);
    this.handlePreviousDemographicQuestion = this.handlePreviousDemographicQuestion.bind(this);

    this.submitQuizResponse = this.submitQuizResponse.bind(this);
    this.handleAnswer = this.handleAnswer.bind(this);

    this.getResultCard = this.getResultCard.bind(this);
    this.retakeTest = this.retakeTest.bind(this);

    this.getStep = this.getStep.bind(this);
    this.getIntroCard = this.getIntroCard.bind(this);
    this.getResponseForStep = this.getResponseForStep.bind(this);
    this.getFirstUnansweredStep = this.getFirstUnansweredStep.bind(this);
    this.getTotalAnswered = this.getTotalAnswered.bind(this);

    this.loadQuizInstance = this.loadQuizInstance.bind(this);
    this.loadStep = this.loadStep.bind(this);
    this.loadIntro = this.loadIntro.bind(this);
    this.loadTopic = this.loadTopic.bind(this);
    this.loadDemographics = this.loadDemographics.bind(this);
    this.getResultById = this.getResultById.bind(this);
    this.loadQuizError = this.loadQuizError.bind(this);
    this.initAnalytics = this.initAnalytics.bind(this);
    this.loadQuizData = this.loadQuizData.bind(this);
    this.loadHomePage = this.loadHomePage.bind(this);

    this.oktaAuth = new OktaAuth({
      issuer: window.OKTA_ISSUER,
      clientId: window.OKTA_CLIENTID,
      redirectUri: `${window.location.origin}/login/callback`,
      scopes: ['openid', 'profile', 'email', 'groups'],
      pkce: true,
      tokenManager: { storage: 'sessionStorage', autoRenew: true },
    });
  }

  componentDidMount() {
    const currentStageQuiz = this.getStageOfQuizByQueryParams();
    const newAnalytics = this.initAnalytics();
    this.setState({ analytics: newAnalytics });
    const asmtid = window.location.pathname.split('/')[2];
    // deeplink
    switch (currentStageQuiz.stage) {
      case 'load':
        this.loadQuizData(asmtid);
        break;

      default:
        this.loadQuizData(asmtid);
        break;
    }
  }

  initAnalytics() {
    const asmtObjIdForAnalaytics = this.props.state.asmtObjId || window.location.pathname.split('/')[2] || defaultAsmtObjId;
    // const asmtObjIdForAnalaytics = defaultAsmtObjId;
    const config = {
      // eslint-disable-next-line react/destructuring-assignment
      userId: this.props.userId,
      is_authenticated: 'yes',
      sessionId: getSessionId(),
      self_assessment_id: asmtObjIdForAnalaytics,
      appcd: 'self-assess',
    };
    const newAnalytics = new Analytics_Tealium(config);
    return newAnalytics;
  }

  handleNextStage() {
    const { state } = this.props;
    this.state.analytics.track('click', {
      event_category: 'page tag',
      event_action: 'button click',
      event_detail: 'startflow',
      event_label: 'getstarted',
      page_cd: 'Self Assessment Home',
      pii_filter: false,
    });
    this.props.history.push(`/assessment/${state.asmtObjId}/Demographics`);
  }

  handleNextQuestion(ref, nextRef, currentStepNumber, answer, question) {
    const { state, dispatch } = this.props;
    this.scrollToRef();

    const response = this.getResponseForStep(currentStepNumber);
    const completed = this.getTotalAnswered(state);

    if (!response) {
      dispatch({ type: QUESTION_UNANSWERED });
    } else {
      this.state.analytics.track('click', {
        event_category: 'page tag',
        event_action: 'radio button select',
        event_detail: answer ? answer.assessmentQuestion.answerCode : '',
        event_label: question ? question.text.substring(0, 29) : '',
        page_cd: `Question ${currentStepNumber}`,
        pii_filter: false,
      });

      this.state.analytics.track('click', {
        event_category: 'click',
        event_action: 'button click',
        event_detail: 'next page',
        event_label: 'next',
        page_cd: `Question ${currentStepNumber}`,
        pii_filter: false,
      });

      if (currentStepNumber === state.total) {
        // if all questions are answered
        if (completed === state.total) {
          this.submitQuizResponse(nextRef);
        } else {
          // go back to the start
          dispatch({ type: NAVIGATE_TO_STEP });
          const unansweredStep = this.getFirstUnansweredStep(state);
          this.props.history.push(`/assessment/${state.asmtObjId}/step/${unansweredStep}`);
        }
      } else {
        this.props.history.push(`/assessment/${state.asmtObjId}/step/${currentStepNumber + 1}`);
      }
    }
  }

  handlePreviousQuestion(ref, currentStepNumber) {
    const { dispatch, state } = this.props;
    dispatch({ type: NAVIGATE_TO_STEP });
    this.state.analytics.track('click', {
      event_category: 'click',
      event_action: 'button click',
      event_detail: 'previous page',
      event_label: 'previous',
      page_cd: `Question ${currentStepNumber}`,
      pii_filter: false,
    });
    if (currentStepNumber === 1) {
      this.props.history.push(`/assessment/${state.asmtObjId}/topic`);
    } else {
      this.props.history.push(`/assessment/${state.asmtObjId}/step/${currentStepNumber - 1}`);
    }
    this.scrollToRef();
  }

  handleNextDemographicQuestion(ref, nextRef, stepNumber) {
    const { state, dispatch } = this.props;
    this.scrollToRef();

    const response = this.getResponseForStep(stepNumber);
    const completed = this.getTotalAnswered(state);

    if (!response) {
      dispatch({ type: QUESTION_UNANSWERED });
    } else if (stepNumber === state.total) {
      // if all questions are answered
      if (completed === state.total) {
        // submit the quiz
        this.submitQuizResponse(nextRef);
      } else {
        // go back to the start
        dispatch({ type: NAVIGATE_TO_STEP });
        const unansweredStep = this.getFirstUnansweredStep(state);
        this.props.history.push(`step/${unansweredStep}`);
      }
    } else {
      dispatch({ type: NAVIGATE_TO_STEP });
      this.props.history.push(`step/${stepNumber + 1}`);
      this.showQuestion();
    }
  }

  handlePreviousDemographicQuestion(ref, stepNumber) {
    const { state } = this.props;
    this.props.history.push(`/assessment/${state.asmtObjId}/step/${stepNumber - 1}`);

    this.scrollToRef();
    this.showQuestion();
  }

  handleAnswer(answer, stepNumber, question) {
    const { dispatch } = this.props;
    dispatch({
      type: QUESTION_ANSWERED,
      payload: { answer, stepNumber },
    });
  }

  getResultById(resultId) {
    try {
      const resultPromise = new Promise((resolve, reject) => {
        getResultByAssessmentId(resultId, (response) => {
          resolve(response.msgDetails);
          const { dispatch } = this.props;
          dispatch({
            type: UPDATE_ASSESSMENT_TITLE,
            payload: {
              assessmentDescription: response.msgDetails.assessmentDescription,
              asmtObjId: response.msgDetails.asmtObjId,
            },
          });
        },
        (err) => { reject(err); });
      });
      return resultPromise;
    } catch (e) {
      // console.log(e);
      throw (e);
    }
  }

  getStep(stepNumber) {
    const { state } = this.props;
    const {
      quiz, quizResponse, showQuestion, isQuestionUnanswered, total,
    } = state;
    let card = null;
    let question = null;
    let index = 0;
    let steps = 0;
    let section = -1;
    let sectionName;
    let sectionCode;
    let selectedAnswer;
    let onNext;
    let onPrevious;

    // loop through the sections to find the question
    for (let i = 0; i < quiz.length; i += 1) {
      if (steps + quiz[i].assessmentQuestions.length < stepNumber) {
        steps += quiz[i].assessmentQuestions.length;
      } else {
        index = stepNumber - steps - 1;
        section = i;
        sectionName = quiz[section].description;
        selectedAnswer = quizResponse.sections[section][index];
        question = quiz[section].assessmentQuestions[index];
        // eslint-disable-next-line no-loop-func
        onNext = (ref, nextRef) => {
          this.handleNextQuestion(ref, nextRef, stepNumber, selectedAnswer, question);
        };
        onPrevious = (ref) => {
          this.handlePreviousQuestion(ref, stepNumber);
        };
        break;
      }
    }

    card = (
      <QuestionCard
        sectionName={sectionName}
        current={stepNumber}
        total={total}
        question={question}
        selectedAnswer={selectedAnswer ? selectedAnswer.assessmentQuestion : selectedAnswer}
        showAlert={isQuestionUnanswered}
        onNext={onNext}
        onPrevious={onPrevious}
        onAnswer={(answer) => this.handleAnswer(answer, stepNumber, question)}
        showQuestion={showQuestion}
        {...this.props}
        analytics={this.state.analytics}
      />
    );
    return card;
  }

  getDemographics() {
    const card = (
      <DemographicsCard
        {...this.props}
        analytics={this.state.analytics}
      />
    );
    return card;
  }

  getTopic() {
    const { state } = this.props;
    const { asmtObjId } = state;
    const card = (
      <TopicPage quizId={asmtObjId} {...this.props} analytics={this.state.analytics} />
    );
    return card;
  }

  getIntroCard() {
    const { state } = this.props;
    document.title = 'Self Assessment App';
    const { preAssessmentData, asmtObjId } = state;
    const card = (
      <IntroCard
        intro={preAssessmentData.intro[0]}
        onNext={this.handleNextStage}
        analytics={this.state.analytics}
      />
    );
    const refelement = document.getElementById('ProgressBar');
    if (refelement) window.scrollTo(0, refelement.offsetTop);
    this.state.analytics.track('nav', {
      page_name: 'Self Assessment App',
    });
    return card;
  }

  getResultCard() {
    const { state } = this.props;
    const { quizSubmitResults } = state;
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    return (
      <RiskWatchResult
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
        analytics={this.state.analytics}
        retakeTest={this.retakeTest}
        assessmentData={quizSubmitResults}
        getPastResult={(resultId) => this.getResultById(resultId)}
      />
    );
  }

  getFirstUnansweredStep(state) {
    const { quizResponse } = state;
    let steps = 1;
    for (let i = 0; i < quizResponse.sections.length; i += 1) {
      for (let j = 0; i < quizResponse.sections[i].length; j += 1) {
        if (quizResponse.sections[i][j]) {
          steps += 1;
        } else {
          break;
        }
      }
    }
    return steps;
  }

  getTotalAnswered(state) {
    const total = state.quizResponse.sections.reduce(
      (totalAnswered, section) => totalAnswered
        + section.reduce(
          (totalQuestionsAnswered, answer) => (answer !== null ? totalQuestionsAnswered + 1 : totalQuestionsAnswered + 0),
          0,
        ),
      0,
    );
    return total;
  }

  getResponseForStep(stepNumber) {
    const { state } = this.props;
    const { quizResponse } = state;
    let response;
    let steps = 0;
    let index;

    for (let i = 0; i < quizResponse.sections.length; i += 1) {
      if (steps + quizResponse.sections[i].length < stepNumber) {
        steps += quizResponse.sections[i].length;
      } else {
        index = stepNumber - steps - 1;
        response = quizResponse.sections[i][index];
        break;
      }
    }

    return response;
  }

  getStageOfQuizByQueryParams() {
    if (queryParamQuizInstanceId != null && queryParamQuizInstanceId !== undefined) {
      return { stage: 'results', quizInstanceId: queryParamQuizInstanceId };
    }
    return { stage: 'load' };
  }

  loadDemographics({ match }) {
    const asmtObjId = match.params.asmtid;
    const { state } = this.props;
    const { preAssessmentData } = state;
    const totalAnswered = 100; // this.getTotalAnswered(this.props.state);
    const completed = 4;// totalAnswered > 0 ? totalAnswered + 1 : 0;

    if (!preAssessmentData) {
      // return <Spinner />;
      this.props.history.push(`/assessment/${asmtObjId}`);
    } else {
      return (
        <Page
          logoAlt={preAssessmentData.logoAlt}
          title={preAssessmentData.title}
          disclaimer={preAssessmentData.disclaimer}
          completed={completed}
          total={totalAnswered}
          analytics={this.state.analytics}
          {...this.props}
        >
          {this.getDemographics()}
        </Page>
      );
    }
  }

  loadTopic({ match }) {
    const { state } = this.props;
    const asmtObjId = match.params.asmtid;
    const { preAssessmentData } = state;
    const totalAnswered = 100;// this.getTotalAnswered(this.props.state);
    const completed = 4; // totalAnswered > 0 ? totalAnswered + 1 : 0;

    // console.log (preAssessmentData);

    if (!preAssessmentData) {
      // return <Spinner />;
      this.props.history.push(`/assessment/${asmtObjId}`);
    } else {
      return (
        <Page
          logoAlt={preAssessmentData.logoAlt}
          title={preAssessmentData.title}
          disclaimer={preAssessmentData.disclaimer}
          completed={completed}
          total={totalAnswered}
          analytics={this.state.analytics}
          {...this.props}
        >
          {this.getTopic()}
        </Page>
      );
    }
  }

  loadIntro() {
    const { state } = this.props;
    const { preAssessmentData } = state;
    const totalAnswered = 100; // this.getTotalAnswered(this.props.state);
    const completed = 0; // totalAnswered > 0 ? totalAnswered + 1 : 0;
    if (!preAssessmentData) {
      return <Spinner />;
    }

    return (
      <Page
        logoAlt={preAssessmentData.logoAlt}
        title={preAssessmentData.title}
        disclaimer={preAssessmentData.disclaimer}
        completed={completed}
        total={totalAnswered}
        analytics={this.state.analytics}
        {...this.props}
      >
        {this.getIntroCard()}
      </Page>
    );
  }

  loadStep({ match }) {
    const asmtObjId = match.params.asmtid;
    const stepNumber = Number.parseInt(match.params.stepNumber, 10);
    const { state } = this.props;
    const {
      quiz, total, quizSubmitResults, preAssessmentData,
    } = state;
    // quiz hasn't been loaded yet
    if (!quiz) {
      return <Redirect push to={`/assessment/${asmtObjId}`} />;
    }

    const completed = this.getTotalAnswered(state);

    // the stepNumber is invalid
    if (!stepNumber || stepNumber < 1 || stepNumber > total) {
      return <Redirect push to={`/assessment/${asmtObjId}`} />;
    }

    // quiz has already been completed
    if (quizSubmitResults) {
      // eslint-disable-next-line no-underscore-dangle
      return <Redirect push to={`/assessment/${asmtObjId}/results/${quizSubmitResults._id}`} />;
    }
    // we have added + 2 because of need to calculate progrees bar by considering first two screen (demographics and topic)
    return (
      <Page
        logoAlt={preAssessmentData.logoAlt}
        title={preAssessmentData.title}
        disclaimer={preAssessmentData.disclaimer}
        completed={completed + 2} // 2 because considering demographic and topic screen completed
        total={total + 3} // 3 because considering demographic , topic and result screen
        analytics={this.state.analytics}
        {...this.props}
      >
        {this.getStep(stepNumber)}
      </Page>
    );
  }

  loadQuizError() {
    const { state } = this.props;
    const {
      total, preAssessmentData, completed, asmtObjId,
    } = state;

    if (!preAssessmentData.logoAlt && !preAssessmentData.title) {
      this.setState((err) => {
        throw new Error();
      });
    } else {
      return (
        <Page
          logoAlt={preAssessmentData.logoAlt}
          title={preAssessmentData.title}
          completed={completed}
          total={total}
          analytics={this.state.analytics}
          {...this.props}
        >
          <AssessmentError asmtid={asmtObjId} />
        </Page>
      );
    }
  }

  loadQuizInstance({ match }) {
    const { state } = this.props;
    queryParamQuizInstanceId = match.params.quizInstanceId;
    const {
      asmtObjId, quizSubmitResults, total, preAssessmentData,
    } = state;

    if (!quizSubmitResults) {
      // return <Spinner />;
      this.props.history.push(`/assessment/${asmtObjId || match.params.asmtid || defaultAsmtObjId}`);
    } else {
      return (
        <Page
          logoAlt={preAssessmentData.logoAlt}
          title={preAssessmentData.title}
          disclaimer={preAssessmentData.disclaimer}
          completed={total}
          total={total}
          analytics={this.state.analytics}
          {...this.props}
        >
          {this.getResultCard()}
        </Page>
      );
    }
  }

  retakeTest(isFirstResult) {
    const { dispatch, state } = this.props;
    const { preAssessmentData, asmtObjId } = state;
    const newAnalytics = this.initAnalytics();
    this.setState({ analytics: newAnalytics });

    let retakePayLoad = {
      DemographicsCard: null,
      focusDiv: 'top',
      isQuestionUnanswered: false,
      isQuizResponseReady: true,
      queryParamQuizInstanceId: null,
      quiz: null,
      quizResponse: {
        startTime: undefined,
        assessmentTopics: [],
        userAttributes: [],
        topicList: [],
      },
      quizSubmitResults: null,
      showQuestion: false,
      topicCard: null,
      topicList: null,
      total: 100,
    };

    if (!isFirstResult || !preAssessmentData) {
      this.loadQuizData(asmtObjId);
      retakePayLoad = { ...retakePayLoad, preAssessmentData: null };
    }
    dispatch({
      type: RETAKE_TEST,
      payload: retakePayLoad,
    });
    this.scrollToRef();
    this.props.history.push(`/assessment/${asmtObjId}`);
  }

  submitQuizResponse(nextRef) {
    // eslint-disable-next-line no-useless-catch
    try {
      nextRef.setAttribute('aria-busy', true);
      const { userId, state, dispatch } = this.props;
      const asmntTemp = state.quizResponse.sections.reduce((acc, val) => acc.concat(val), []);
      // console.log(asmntTemp);
      const assessmentTopics = [];
      /* refactor this block before PR */
      asmntTemp.forEach((item) => {
        const Item = item;
        const existing = assessmentTopics.filter((v) => v.code === Item.code);
        if (existing.length) {
          const existingIndex = assessmentTopics.indexOf(existing[0]);
          assessmentTopics[existingIndex].assessmentQuestion.push(Item.assessmentQuestion);
        } else {
          // if (typeof Item.assessmentQuestion == 'array')
          Item.assessmentQuestion = [Item.assessmentQuestion];
          assessmentTopics.push(Item);
        }
      });

      const quizSubmitResponse = {
        asmtObjId: state.asmtObjId,
        assessmentId: state.assessmentId,
        startTime: state.quizResponse.startTime,
        endTime: new Date().toJSON(),
        userId,
        assessmentTopics,
        userAttributes: state.quizResponse.userAttributes,
      };
      submitAssessment(
        quizSubmitResponse,
        (response) => {
          dispatch({ type: QUIZ_SUBMITTED, payload: response.data.msgDetails });
          nextRef.setAttribute('aria-busy', false);
          document.title = 'Results - Self Assessment App';
          // eslint-disable-next-line no-underscore-dangle
          this.props.history.push(`/assessment/${state.asmtObjId}/results/${response.data.msgDetails._id}`);
        },
        (err) => {
          dispatch({ type: QUIZ_SUBMIT_ERROR, payload: { error: err } });
          this.setState(() => {
            this.props.history.push('/assessmentError');
          });
        },
      );
    } catch (e) {
      throw e;
    }
  }

  scrollToRef() {
    const refelement = document.getElementById('ProgressBar');
    window.scrollTo(0, refelement.offsetTop);
  }

  loadQuizResults(quizInstanceId) {
    const { dispatch } = this.props;
    getResultByAssessmentId(
      quizInstanceId,
      (resp) => {
        const quizRslt = resp.msgDetails;
        const total = 2
          // resp.quiz.userAttributes.length +
          // eslint-disable-next-line no-shadow
          + quizRslt.postedAnswers.assessmentTopics.reduce((total, section) => {
            let Total = total;
            Total += section.assessmentQuestion.length;
            return Total;
          }, 0);

        dispatch({
          type: RESULT_LOADED,
          payload: {
            quizSubmitResults: quizRslt,
            quiz: resp.postedAnswers,
            isQuestionUnanswered: false,
            total,
          },
        });
      },
      (err) => {
        dispatch({ type: RESULT_LOAD_ERROR, payload: { error: err } });
        this.setState(() => {
          throw new Error(err);
        });
      },
    );
  }

  loadQuizData(asmtid, asmtLink) {
    const { dispatch } = this.props;

    dispatch({
      type: ASSESSMENTOBJID,
      asmtObjId: asmtid || defaultAsmtObjId,
    });
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (window.sessionStorage.getItem('okta-token-storage')) {
      const accessObject = JSON.parse(
        window.sessionStorage.getItem('okta-token-storage'),
      );
      if (accessObject.accessToken && accessObject.accessToken.accessToken) {
        getPreAssessment(asmtid || defaultAsmtObjId,
          (response) => {
            const preAss = response.data.msgDetails[0];

            preAss.demographicQuestions.forEach((q) => {
              q.options.splice(0, 0, { code: '', description: `Select ${q.category}` });
            });

            dispatch({
              type: QUIZ_LOADED,
              payload: {
                preAssessmentData: response.data.msgDetails[0],
              },
            });
          },
          (err) => {
            dispatch({ type: QUIZ_LOAD_ERROR, payload: { error: err } });
            this.setState(() => {
              throw new Error(err);
            });
          });
        this.props.history.push(asmtLink);
      } else {
        console.log('Error coming from getPreAssesment: Error in triggering the Okta')
      }
    } else {
      console.log('Error coming from getPreAssesment')
    }
    // this.props.history.push(asmtLink);
  }

  // chooser page
  loadHomePage() {
    const { state, dispatch } = this.props;
    document.title = 'Travelers Self-Assessments';
    if (state.asmtObjId) {
      dispatch({
        type: RESET_QUIZ_DATA,
        payload: null,
      });
    }

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    return (
      <HomePage loadQuizData={this.loadQuizData} />
    )
  }

  render() {
    return (
      <Security restoreOriginalUri={restoreOriginalUri} oktaAuth={this.oktaAuth}>
        <Switch>
          <Route path="/login/callback" component={LoginCallback} />
          <SecureRoute path="/assessment/:asmtid" exact render={this.loadIntro} />
          <SecureRoute path="/assessment/:asmtid/topic" exact render={this.loadTopic} />
          <SecureRoute path="/assessment/:asmtid/Demographics" exact render={this.loadDemographics} />
          <SecureRoute path="/assessment/:asmtid/step/:stepNumber" exact render={this.loadStep} />
          <SecureRoute path="/assessment/:asmtid/results/:quizInstanceId" exact render={this.loadQuizInstance} />
          <SecureRoute path="/assessment/:asmtid/assessmentError" exact render={this.loadQuizError} />
          {/* chooser page  */}
          <SecureRoute exact path="/" component={this.loadHomePage} />
          <SecureRoute component={this.loadHomePage} />
        </Switch>
      </Security>
    );
  }
}

QuizForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  userId: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};
