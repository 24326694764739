/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Demographics from './Demographics';
import {
  DEMOGRAPHICS_DATA,
} from '../../../actions/quiz/states';

class DemographicsContainer extends Component {
  constructor(props) {
    super(props);
    const { DemographicsData, UserIndustry } = this.props;
    this.state = {
      DemographicsData,
      IsAllSelect: true,
      IsDisplayValidityMsg: false,
      UserIndustry,
      formdata: this.getFormData(),
    };
  }

  componentDidMount() {
    const { state } = this.props;
    const { UserIndustry } = this.state;
    if (!state.quizResponse.userAttributes) {
      if (UserIndustry) {
        this.setState({ formdata: { Industry: UserIndustry, Location: null, Size: null } });
      }
    } else if (state.quizResponse.userAttributes
      && state.quizResponse.userAttributes.Industry
      && state.quizResponse.userAttributes.Location
      && state.quizResponse.userAttributes.Size) {
      const defaultValues = state.quizResponse.userAttributes;
      this.setState({ formdata: defaultValues });
    }
    this.props.analytics.track('nav', {
      page_name: 'Self Assessment Demographics',
    });
  }

  getFormData = () => {
    const fd = {};
    const { state, DemographicsData } = this.props;
    // eslint-disable-next-line eqeqeq
    if (state.quizResponse && state.quizResponse.userAttributes && state.quizResponse.userAttributes != '') {
      return state.quizResponse.userAttributes;
    }
    if (DemographicsData) {
      DemographicsData.forEach((d) => {
        fd[d.category] = d.userInfo ? d.userInfo.defaultIndustry ? d.userInfo.defaultIndustry.code : null : null;
      });
    }
    return fd;
  }

  checkAllDropdownSelected = () => {
    const { formdata } = this.state;
    const fdObject = formdata;
    for (const property in formdata) {
      if (fdObject[property] == null || fdObject[property] === '') {
        return false;
      }
    }
    return true;
  }

  handleTealiumNextCall = () => {
    const demographics = this.state.formdata;
    // eslint-disable-next-line guard-for-in
    for (const property in demographics) {
      this.props.analytics.track('click', {
        event_category: 'page tag',
        event_action: 'dropdown',
        event_detail: demographics[property].toLowerCase(),
        event_label: property.toLowerCase(),
        page_cd: 'Demographics',
        pii_filter: false,
      });
    }
    this.props.analytics.track('click', {
      event_category: 'page tag',
      event_action: 'button click',
      event_detail: 'next page',
      event_label: 'next',
      page_cd: 'Demographics',
      pii_filter: false,
    });
  }

  handleSubmitOnClick = (e) => {
    if (!this.checkAllDropdownSelected()) {
      this.setState({ IsAllSelect: true, IsDisplayValidityMsg: true });
    } else {
      const { dispatch, state } = this.props;
      const { quizResponse, asmtObjId } = state;
      const { formdata } = this.state;
      quizResponse.userAttributes = formdata;
      dispatch({
        type: DEMOGRAPHICS_DATA,
        payload: {
          quizResponse: {
            userAttributes: quizResponse.userAttributes,
            sections: quizResponse.sections,
            topicList: quizResponse.topicList,
          },
        },
      });

      this.setState({ IsAllSelect: true });
      this.handleTealiumNextCall();
      const { history } = this.props;
      history.push(`/assessment/${asmtObjId}/topic`);
    }
    e.preventDefault();
  }

  handleBackOnClick = (event) => {
    this.props.analytics.track('click', {
      event_category: 'page tag',
      event_action: 'button click',
      event_detail: 'previous page',
      event_label: 'back',
      page_cd: 'Demographics',
      pii_filter: false,
    });
    const { state } = this.props;
    const { asmtObjId } = state;
    const { history } = this.props;
    history.push(`/assessment/${asmtObjId}`);
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      formdata: {
        ...prevState.formdata,
        [name]: value,
      },
    }), () => {
      const { dispatch, state } = this.props;
      const { quizResponse } = state;
      const { formdata } = this.state;
      quizResponse.userAttributes = formdata;

      dispatch({
        type: DEMOGRAPHICS_DATA,
        payload: {
          quizResponse: {
            userAttributes: quizResponse.userAttributes,
            sections: quizResponse.sections,
            topicList: quizResponse.topicList,
          },
        },
      });
    });
  }

  render() {
    return (

      <Demographics
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.state}
        handleOnChange={this.handleOnChange}
        handleSubmitOnClick={this.handleSubmitOnClick}
        handleBackOnClick={this.handleBackOnClick}
      />

    );
  }
}

DemographicsContainer.propTypes = {
  DemographicsData: PropTypes.instanceOf(Object).isRequired,
  // eslint-disable-next-line react/require-default-props
  UserIndustry: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  analytics: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default DemographicsContainer;
