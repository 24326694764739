/* eslint-disable max-len */
import { Server, Response } from 'miragejs';
const getAssessmentQuestions = require('./data/getAssessmentQuestions.json');
const getCommonMenu = require('./data/getCommonMenu.json');
const getResultByAssessmentId = require('./data/getResultByAssessmentId.json');
const getTopicByAssessment = require('./data/getTopicByAssessment.json');
const preAssessmentData = require('./data/preAssessmentData.json');
const preAssessmentDataFleetSafety = require('./data/preAssessmentDataFleetSafety.json');
const preAssessmentDataImageUndefine = require('./data/preAssessmentDataImageUndefine.json');
const preAssessmentDataNoImage = require('./data/preAssessmentDataNoImage.json');
const preAssessmentDataNoInd = require('./data/preAssessmentDataNoInd.json');
const submitAssessment = require('./data/submitAssessment.json');
const allPreAssessmentData = require('./data/allPreAssessments.json')

export default function mockServer({ environment = 'development' } = {}) {
  const server = new Server({
    environment,

    routes() {
      this.get(
        '/api/assessmentXPI/getcommonmenu',
        () => new Response(200, {}, getCommonMenu),
      );

      this.get(
        '/api/assessmentXPI/getPreAssessment/:asmtObjId',
        (schema, req) => {
          if (req.params.asmtObjId === '8') {
            return new Response(500, {}, {});
          }
          // let data = preAssessmentData;
          const responseData = allPreAssessmentData.filter((assessment) => assessment.id === req.params.asmtObjId);
          const data = responseData[0].response
          // switch (req.params.asmtObjId) {
          //   case '5fecad3dd63fac001b9f2998':
          //     data = preAssessmentDataNoInd;
          //     break;
          //   case '6022311295cc19131c90347e':
          //     data = preAssessmentData;
          //     break;
          //   case '6022311295cc19131c90347a':
          //     data = preAssessmentDataNoImage;
          //     break;
          //   case '6127dc03787548000e4e7f0f':
          //     data = preAssessmentDataFleetSafety;
          //     break;
          //   case '6022311295cc19131c90347b':
          //     data = preAssessmentDataImageUndefine;
          //     break;
          //   default:
          //     break;
          // }
          return new Response(200, {}, data);
        }, { timing: 500 },
      );

      this.put(
        '/api/assessmentXPI/getAssessmentQuestions',
        (schema, req) => {
          if (JSON.parse(req.requestBody).assessmentId === '8') {
            return new Response(500, {}, {});
          }
          JSON.parse(req.requestBody).topics.push('Safety Management');
          const asmtQuestion = { ...getAssessmentQuestions };
          const responseData = asmtQuestion.msgDetails.filter((i) => JSON.parse(req.requestBody).topics.includes(i.code));
          asmtQuestion.msgDetails = responseData;
          return new Response(200, {}, asmtQuestion);
        },
      );

      this.get(
        '/api/assessmentXPI/getTopicByAssessment/:asmtObjId',
        (schema, req) => {
          if (req.params.asmtObjId === '8') {
            return new Response(500, {}, {});
          }
          return new Response(200, {}, getTopicByAssessment);
        },
      );

      this.post(
        '/api/assessmentXPI/submitAssessment',
        (schema, req) => {
          if (JSON.parse(req.requestBody).assessmentId === '8') {
            return new Response(500, {}, {});
          }
          const submittedTopics = JSON.parse(req.requestBody).assessmentTopics.map((result) => result.code);
          const submitResponse = submitAssessment.msgDetails.assessmentResult.topicFeedback.filter((topic) => submittedTopics.includes(topic.topic));
          submitAssessment.msgDetails.assessmentResult.topicFeedback = submitResponse;
          submitAssessment.msgDetails.postedAnswers = submitAssessment.msgDetails.postedAnswers.filter((answers) => submittedTopics.includes(answers.code));

          return new Response(200, {}, submitAssessment);
        },
      );

      this.get(
        '/api/assessmentXPI/getAsmntResultByInstance/:assessmentInstanceId',
        (req, res) => {
          if (req.params.assessmentId === '8') {
            setTimeout(() => new Response(500, {}, {}), 2000);
            return new Response(500, {}, {});
          }
          return new Response(200, {}, getResultByAssessmentId);
        },
      );
    },
  });

  return server;
}
