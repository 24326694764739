import React from 'react';
import PropTypes from 'prop-types';

function getAnswerCard(answer, sectionName, questionNum, selectedAnswer, onAnswer) {
  const isSelected = answer.code === (selectedAnswer ? selectedAnswer.answerCode : null);
  const answerCard = (
    // Text within the button label makes it WCAG compliant //
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <button
      type="button"
      id={`nppt-${sectionName}-step-${questionNum}-${answer.code}`}
      role="radio"
      aria-checked={isSelected}
      className={`pt-button-w-question pt-button-w-question--centered ${
        answer.code === (selectedAnswer ? selectedAnswer.answerCode : null)
          ? 'pt-button-w-question--selected'
          : ''
      }`}
      onClick={() => onAnswer(answer)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: answer.text }}
    />
  );

  return answerCard;
}

export function EightQuestionCard({
  sectionName, questionNum, answers, selectedAnswer, onAnswer,
}) {
  const results = [];
  for (let i = 0; i < answers.length; i += 1) {
    results.push(
      <div className="tds-row pt-button-group-row" key={i}>
        <div className="tds-col-xs-6">
          {getAnswerCard(answers[i], sectionName, questionNum, selectedAnswer, onAnswer)}
        </div>
        {i + 1 < answers.length ? (
          <div id={i += 1} className="tds-col-xs-6">
            {getAnswerCard(answers[i], sectionName, questionNum, selectedAnswer, onAnswer)}
          </div>
        ) : null}
      </div>,
    );
  }
  return results;
}

EightQuestionCard.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.object),
  selectedAnswer: PropTypes.objectOf,
  onAnswer: PropTypes.func,
};
