import React from 'react';
import { TdsButton, TdsCard, TdsCardContent } from '@trv-tds/react';

const Card = ({loadQuizData, homePageId, asmt, index}) => {
    const handleOnClick = (assessmentLink, asmtObjID) => {
        loadQuizData(asmtObjID,assessmentLink);
    }
    return(
        <>
            <TdsCard size="small" className="sa-card" id={`${homePageId}-card-${index}`}>
              <img src={asmt.assessmentImage} alt="" slot="image-top" />
              <TdsCardContent id={`${homePageId}-card-context-${index}`}>
                <div slot="header" id={`${homePageId}-title-${index}`}>
                  {asmt.assessmentTitle}
                </div>
                <p id={`${homePageId}-desc-${index}`}>{asmt.assessmentDescription}</p>
              </TdsCardContent>
              <div slot="footer">
                  <TdsButton onClick={() => handleOnClick(asmt.assessmentLink,asmt.asmtObjID)} variant="tertiary" size="large" id={`${homePageId}-btn-${index}`}>
                    <span>Start</span>
                    <span className="tds-sr-only">
                      {` ${asmt.assessmentTitle} `}
                    </span>
                    <span> Assessment</span>
                  </TdsButton>
              </div>
            </TdsCard>
        </>
    )
}
export default Card;