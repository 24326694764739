import React from 'react';
import Countdown from './Custom/CountDown';
import propTypes from 'prop-types';
import {
  TdsButton, TdsModalButtons, TdsModal,
} from '@trv-tds/react';

const SessionTimeout = (props) => {
  const ModalElement = React.useRef()
  const ModalObject = React.useRef()

  React.useEffect(() => {
    ModalObject.current = ModalElement.current
  }, [props])

  const closeModal = () => {
    props.reset();
    ModalObject.current.remove()
  }

  React.useEffect(() => {
    ModalObject.current.open()
  })

  // render() {
  return (
<div>
      <TdsModal
        ref={ModalElement}
        id="timeout-modal"
        dialog-title="Warning"
        size="small"
        data-enhanced-modal="true"
        data-do-not-move="true"
        onTdsClosed={() => closeModal()}
      >
        <p className="tds-bold" id="timeout-modal-description">
          Due to inactivity, you will be logged off in:
        </p>
        <div className="timer-background-color">
          <div className="tds-bold">
            <Countdown time={props.time} />
          </div>
        </div>
        <TdsModalButtons slot="footer">
          <TdsButton variant="primary" data-dismiss="modal" id="timeout-stay-btn">Stay</TdsButton>
        </TdsModalButtons>
      </TdsModal>
    </div>
  );
};


SessionTimeout.propTypes = {
  reset: propTypes.func,
  time: propTypes.any,
};

export default SessionTimeout;
