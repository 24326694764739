import PropTypes from 'prop-types';
import React from 'react';
import DemographicsContainer from './DemographicsContainer';
import BannerInfo from '../Banner/BannerInfo';
import '@trv-tds/core/dist/sass/core.scss';
import { getPreAssessment } from '../../../api/QuizApi';
import { Spinner } from '../../shared/Spinner';

class DemographicsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DemographicsCard: null,
      isLoading: true,
      UserIndustry: null, // 'Construction'
    };
  }

  componentDidMount() {
    const { state } = this.props;
    document.title = 'Demographics - Self Assessment App';
    if (state.preAssessmentData) {
      this.setState({ DemographicsCard: state.preAssessmentData, isLoading: false });
    } else {
      getPreAssessment(state.asmtObjId,
        (response) => {
          this.setState({ DemographicsCard: response.data.msgDetails, isLoading: false });
        },
        (err) => {
          // console.log('getPreAssementError', err);
        });
    }
    const refelement = document.getElementById('ProgressBar');
    if (refelement) window.scrollTo(0, refelement.offsetTop);
  }

  render() {
    // eslint-disable-next-line no-shadow
    const { DemographicsCard, isLoading, UserIndustry } = this.state;
    return (
      <div className="tds-mb-xxxl bg-color-white" style={{ background: '#ffffff' }}>

        {isLoading ? (
          <div className="tds-centered">
            {' '}
            <Spinner />
            {' '}
          </div>
        )
          : (
            <div className="tds-row tds-mx-0">
              <div className="tds-col rw-contrast-block tds-py-xl">
                <BannerInfo
                  text={DemographicsCard.bannerText}
                  id="demographics"
                  description={DemographicsCard.bannerDescription}
                />
              </div>
              <div className="tds-col-lg block_right tds-py-xl">
                <DemographicsContainer
                  DemographicsData={DemographicsCard.demographicQuestions}
                  UserIndustry={UserIndustry}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...this.props}
                />
              </div>
            </div>
          )}

      </div>
    );
  }
}

DemographicsCard.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
};

export default DemographicsCard;
