import React, { useEffect }  from 'react';
import { Footer } from 'trv-tds-react';
import PropTypes from 'prop-types';
import { getSessionId } from '../../../utils/analytics';

const TDSFooter = props => {

  const getCookieValue = function (cookieName) {
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
    return decodeURIComponent(document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(cookieName).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || null;
  };
  /* Load feedback link once qualtrics is loaded */
  window.TLTSID = getCookieValue('TLTSID');
  window.userID = props.userId;
  window.sessionID = getSessionId();

  return (
    <Footer>
      <Footer.Copy />
      {props.footer && props.footer.footerLinks && (
        <>
          <Footer.Links>
            {props.footer.footerLinks.map(obj => (
              <Footer.LinkItem key={obj.id} id={obj.id} href={obj.href} target={obj.target}>
                {obj.label}
              </Footer.LinkItem>
            ))}
          </Footer.Links>
        </>
      )}
      {props.footer && props.footer.footerDisclaimer && (
        <Footer.Disclaimer>{props.footer.footerDisclaimer}</Footer.Disclaimer>
      )}
    </Footer>
  );
};

TDSFooter.propTypes = {
  props: PropTypes.object,
};

export default TDSFooter;
