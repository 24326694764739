import PropTypes from 'prop-types';
import React from 'react';
import '@trv-tds/core/dist/sass/tokens/_tokens.scss';

function BannerInfo(props) {
  const { text, id, description } = props;
  return (
    <article className="tds-py-xxxl tds-px-xl rw-contrast-block">
      <div className="tds-h1--small mob-tds-h1--small" id={`${id}-text`} style={{ color: '#ffffff' }}>{text}</div>
      <div className="tds-h4 tds-margin-tb-medium">
        {description}
      </div>
    </article>
  );
}

BannerInfo.propTypes = {
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default BannerInfo;
