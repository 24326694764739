import React, { Component } from 'react';
import { ApplicationError } from './ApplicationError';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <ApplicationError />
        </>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
