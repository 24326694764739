import React from 'react';
import PropTypes from 'prop-types';

export function Alert({ id, alertType, content }) {
  return (
    <div className={`tds-inline-alert--${alertType}`} id={id} tabIndex="-1">
      <p>
        <span className="tds-sr-only">Informational: </span>
        {content}
      </p>
    </div>
  );
}

Alert.propTypes = {
  id: PropTypes.string,
  alertType: PropTypes.oneOf(['info', 'warning', 'success', 'error']),
  content: PropTypes.element,
};

Alert.defaultProps = {
  id: null,
  alertType: 'error',
  content: null,
};
