/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import React from 'react';
import TopicChecklist from './TopicChecklist';
import BannerInfo from '../Banner/BannerInfo';
import { getTopicByAssessment } from '../../../api/QuizApi';
import { TOPIC_CARD } from '../../../actions/quiz/states';
import { Spinner } from '../../shared/Spinner';

class TopicPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // quizId: props.quizId,
      topicCard: {},
      IsLoading: true,
    };
    this.bannerRef = React.createRef();
  }

  componentDidMount() {
    const { state, quizId } = this.props;
    document.title = 'Topics - Self Assessment App';
    const industrySelected = this.props.state.quizResponse?.userAttributes?.Industry ?? '';
    this.props.analytics.track('nav', {
      page_name: 'Self Assessment Topic',
      industry_selected: industrySelected.toLowerCase(),
    });

    // check global state.
    if (state.topicCard) {
      this.setState({ topicCard: state.topicCard, IsLoading: false });
    } else {
      const { dispatch } = this.props;
      getTopicByAssessment(quizId,
        (res) => {
          const resTopicCard = res.data.msgDetails;
          this.setState({ topicCard: resTopicCard, IsLoading: false });

          dispatch({
            type: TOPIC_CARD,
            payload: { topicCard: resTopicCard, assessmentId: resTopicCard.assessmentID },
          });
        },
        (err) => {
          dispatch({ type: TOPIC_CARD, payload: { error: err } });
          this.setState(() => {
            throw new Error(err);
          });
        });
    }
    const refelement = document.getElementById('ProgressBar');
    if (refelement) window.scrollTo(0, refelement.offsetTop);
  }

  getTopics() {
    const { state } = this.props;
    const { topicCard } = this.state;
    if (state.topicList) {
      return state.topicList;
    }
    topicCard.assessmentTopics.forEach((i) => { const e = i; e.IsChecked = true; });
    return topicCard.assessmentTopics;
  }

  render() {
    const { topicCard, IsLoading } = this.state;
    return (
      <div ref={this.bannerRef} className="tds-mb-xxxl bg-color-white" style={{ background: '#ffffff' }}>
        {IsLoading ? (
          <div className="tds-centered">
            {' '}
            <Spinner />
            {' '}
          </div>
        )
          : (
            <div className="tds-row">
              <div className="tds-col-md-12 tds-col-sm tds-col-lg-6">
                <BannerInfo text={topicCard.bannerText} id="topics" description={topicCard.bannerDescription} />
              </div>
              <div className="tds-col-md-12 tds-col-sm tds-col-lg-6 tds-py-xxl rw-mob-pt-s">
                <TopicChecklist topicList={this.getTopics()} {...this.props} />
              </div>
            </div>
          )}
      </div>
    );
  }
}

TopicPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  quizId: PropTypes.string.isRequired,
  state: PropTypes.any.isRequired,
  analytics: PropTypes.any.isRequired,
};

export default TopicPage;
