/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import React from 'react';
import { Header } from 'trv-tds-react';
import CancelModalDialog from '../../../shared/error/CancelModal/index';
import { ReactComponent as  X124  } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/x-1-24.svg';

export class TDSHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
    this.cancelButtonClickHandler = this.cancelButtonClickHandler.bind(this);
    this.modalLeaveButtonClickHandler = this.modalLeaveButtonClickHandler.bind(this);
    this.modalStayButtonClickHandler = this.modalStayButtonClickHandler.bind(this);
    this.redirectToReturnUrl = this.redirectToReturnUrl.bind(this);
  }

  redirectToReturnUrl() {
    const homeLink = this.props.header
      ? this.props.header.homeMenu.href
      : '';
    window.location.href = homeLink? homeLink : '/';
  }


  modalStayButtonClickHandler() {
    const winUrl = window.location.href;
    const pageSrc = winUrl.indexOf('Demographics')>0 ? 'SELFASSESSMENTDEMO' : (winUrl.indexOf('topic')>0 ? 'SELFASSESSMENTTOPIC' : (winUrl.indexOf('step')>0 ? 'SELFASSESSMENTQUESTIONS' : (winUrl.indexOf('results')>0 ? 'SELFASSESSMENTRESULTS' : 'SELFASSESSMENTHOME' ) ) );
    this.setState({ showModal: false });
  }

  modalLeaveButtonClickHandler() {
    const winUrl = window.location.href;
    this.setState({isBusy:true }); 
    const pageSrc = winUrl.indexOf('Demographics')>0 ? 'SELFASSESSMENTDEMO' : (winUrl.indexOf('topic')>0 ? 'SELFASSESSMENTTOPIC' : (winUrl.indexOf('step')>0 ? 'SELFASSESSMENTQUESTIONS' : (winUrl.indexOf('results')>0 ? 'SELFASSESSMENTRESULTS' :  (winUrl.indexOf('assessment')>0 ? 'SELFASSESSMENTINTRO' : 'SELFASSESSMENTHOME' ) )) );
    setTimeout(()=>{
      this.redirectToReturnUrl();
    }, 3000);
    
  }

  cancelButtonClickHandler = () => {
    const { state } = this.props;
    const winUrl = window.location.pathname;
    const pageSrc = winUrl.indexOf('Demographics')>0 ? 'SELFASSESSMENTDEMO' : (winUrl.indexOf('topic')>0 ? 'SELFASSESSMENTTOPIC' : (winUrl.indexOf('step')>0 ? 'SELFASSESSMENTQUESTIONS' : (winUrl.indexOf('results')>0 ? 'SELFASSESSMENTRESULTS' : (winUrl.indexOf('assessment')>0 ? 'SELFASSESSMENTINTRO' :  'SELFASSESSMENTHOME' ) )) );
    winUrl.indexOf('results') === -1 && pageSrc !== 'SELFASSESSMENTHOME' ?
      this.setState({ showModal: true }) :  this.redirectToReturnUrl();
  }

  render() {
    const homeLink = this.props.header
      ? this.props.header.homeMenu.href
      : '';

    const modalWindow = this.state.showModal ?
      <CancelModalDialog
        showModal={this.state.showModal}
        isBusy={this.state.isBusy}
        modalLeaveButtonClickHandler={this.modalLeaveButtonClickHandler}
        modalStayButtonClickHandler={this.modalStayButtonClickHandler}
      /> : null;

    return (
      <Header
        appName={
          <span>
            MyTravelers<sup>&reg;</sup> For Business
          </span>
        }
        href={homeLink || 'https://www.travelers.com'}
      >
        {this.props.header && this.props.header.homeMenu && (
          <>
            <Header.Actions>
              <Header.ActionItem>
                <button
                  className="tds-iconbutton tds-iconbutton--large tds-iconbutton--large"
                  onClick={this.cancelButtonClickHandler}
                  aria-label="Close"
                  title="Close"
                >
                  <X124 className=" tds-iconbutton__icon tds-icon-24"  aria-hidden="true" focusable="false"/>
                </button>
                {modalWindow}

              </Header.ActionItem>
            </Header.Actions>
          </>
        )}
      </Header>
    );
  }
}

export default TDSHeader;
