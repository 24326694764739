import React, { Component } from 'react';
import SessionTimeout from '../error/SessionTimeout';
import propTypes from 'prop-types';
import {  cookies } from '@trv-ent-web/auth'; 

// Idle timeout events
const idleTimeoutEvents = [
  'load',
  'mousemove',
  'mousedown',
  'mousewheel',
  'DOMMouseScroll',
  'click',
  'scroll',
  'keypress',
  'keydown',
  'keyup',
  'touchstart',
];

const clearSessionData = () => {
  const cookieNames = [
    'SMSESSION',
    'TravAuthE',
    'TravAuthV',
    'TravSessionId',
    'channels'
  ];
  const options = {
    domain:
      window.location.hostname === 'localhost' ? 'localhost' : 'travelers.com',
    path: '/'
  };
  cookieNames.forEach(key => {
    cookies.delete(key, options);
  });
};
class AutoTimeout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      idleTimeout: 20 * 60 * 1000, // 20m idle timeout 
      warningTimeout: 2 * 60 * 1000, //2m warning message displayed
    };
  }

  componentDidMount() {
    // auth.initialize(window); // comment to prevent default behaviour
    this.setupTimer();
  }

  

  setupTimer = () => {
    this.startTimer();
    idleTimeoutEvents.forEach(ev => {
      window.addEventListener(ev, this.resetIdleTimer);
    });
  };

  startTimer = () => {
    this.idleTimer = setTimeout(
      this.onIdleTimeout,
      this.state.idleTimeout - this.state.warningTimeout,
    );
  };

  onIdleTimeout = (event) => {
    // Listen to events, if event occurs, call reset
    idleTimeoutEvents.forEach(event => {
      window.removeEventListener(event, this.resetIdleTimer);
    });
    this.setState({ alert: true });

    this.warningTimer = setTimeout(() => {
      window.location.replace('/api/auth/logoff'); //call ebusiness auth service to log off
    }, this.state.warningTimeout);
  };



  resetIdleTimer = () => {
    clearTimeout(this.idleTimer);
    this.startTimer();
  };

  stayLoggedIn = () => {
    clearTimeout(this.warningTimer);
    clearTimeout(this.idleTimer);
    this.setState({ alert: false });
    this.setupTimer();
  };

  render() {
    return (
      <>
        {this.state.alert && (
          <SessionTimeout
            time={this.state.warningTimeout / 1000}
            reset={this.stayLoggedIn}
            id="sessiontimeout-autotimeout"
          />
        )}
        {this.props.children}
      </>
    );
  }
}


AutoTimeout.propTypes = {
  children: propTypes.object,
};

export default AutoTimeout;
